@font-face {
  font-family: "Aileron-Regular";
  font-display: auto;
  src: local("Aileron-Regular"), url(Aileron-Regular.woff) format("woff");
  src: local("Aileron-Thin"), url(Aileron-Thin.woff) format("woff");
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  max-height: 100vh;
  max-width: 100%;
  overflow: hidden;
  font-family: "Aileron-Regular";
}

#root {
  max-height: 100vh;
  max-width: 100%;
  display: block;
}

.container-footer {
  margin-top: 5vh;
  border-top: 1px solid black;
  height: 10vh;
  position: block;
  bottom: 0;
  color: black;
  width: 100%;
}

.free-minutes {
  height: 10%;
}

.logo {
  margin-top: 5%;
  height: 100%;
}

.homepage-div {
  height: 100%;
}

.container-bottoni {
  height: 13%;
  margin-top: 17%;
  text-align: center;
}
.free-minutes {
  height: 10vh;
}

.container-footer-equal {
}

button {
  background: #ededed;
  border: 1pt solid #b5b5b5;
  border-width: 1px;
  border-color: #00cdcf;
  padding: 10px 30px;
  border-radius: 3px;
  cursor: pointer;
}

button:focus {
  background: #e5e5e5;
  outline: none;
  -webkit-box-shadow: inset 0px 0px 5px #c1c1c1;
  -moz-box-shadow: inset 0px 0px 5px #c1c1c1;
  box-shadow: inset 0px 0px 5px #c1c1c1;
}
.update-notification {
  background-color: #ffffff;
  color: #222222;
  display:block;
  position: absolute;
  bottom:0;
  right:0;
  padding:0.8em 1.2em;
  text-decoration: none;
}

.text-ads {
  margin-left: 15%;
  margin-top: 10%;
  font-size: 1.1em;
  text-align: left;
  color: #523492;
  line-height: 1;
}
.text-ads-dark {
  margin-left: 15%;
  margin-top: 10%;
  font-size: 1.1em;
  text-align: left;
  color: white;
  line-height: 1;
}
.text-task {
  margin-top: 5%;
  font-size: 1.1em;
  text-align: left;
  color: #00cdcf;
  line-height: 1;
}

.text-task-dark {
  margin-top: 5%;
  font-size: 1.1em;
  text-align: left;
  color:white;
  line-height: 1;
}

.text-new-item {
  margin-top: 5%;
  font-size: 1.1em;
  margin-left: 15%;
  text-align: left;
  color: #00cdcf;
  font-weight: 300;
}

.col3 {
  border: 1px solid white;
}

.col3-center {
  display: inline-block;
  margin: 0 auto;
  width: 25%;
  height: 100%;
  text-align: center;
}

.col3-right {
  text-align: center;
  display: inline-block;
  margin: 0 auto;
  width: 25%;
  height: 100%;
}

.col3-right-extreme {
  text-align: center;
  display: inline-block;
  margin: 0 auto;
  width: 12.5%;
  height: 100%;
}

.col3-left-extreme {
  text-align: center;
  float: left;
  width: 12.5%;
  height: 100%;
}

.col3-left {
  text-align: center;
  float: left;
  width: 25%;
  height: 100%;
}

.col-footer-left {
  float: left;
  width: 40%;
  height: 100%;
}
.col-footer-center {
  display: inline-block;
  margin: 0 auto;
  width: 40%;
  height: 100%;
}
.col-footer-right {
  display: inline-block;
  margin: 0 auto;
  width: 20%;
  height: 100%;
  text-align: center;
}
.borderRight {
  border-right: 1px solid black;
  border-left: 1px solid black;
  height: 100%;
}

.heartbeat {
  -webkit-animation: heartbeat 2.5s linear 8s infinite;
  animation: heartbeat 2.5s linear 8s infinite;
}

@-webkit-keyframes heartbeat {
  from {
    -webkit-transform: scale(1);
    transform: scale(1);
    -webkit-transform-origin: center center;
    transform-origin: center center;
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }
  10% {
    -webkit-transform: scale(0.91);
    transform: scale(0.91);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }
  17% {
    -webkit-transform: scale(0.98);
    transform: scale(0.98);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }
  33% {
    -webkit-transform: scale(0.87);
    transform: scale(0.87);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }
  45% {
    -webkit-transform: scale(1);
    transform: scale(1);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }
}
@keyframes heartbeat {
  from {
    -webkit-transform: scale(1);
    transform: scale(1);
    -webkit-transform-origin: center center;
    transform-origin: center center;
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }
  10% {
    -webkit-transform: scale(0.91);
    transform: scale(0.91);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }
  17% {
    -webkit-transform: scale(0.98);
    transform: scale(0.98);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }
  33% {
    -webkit-transform: scale(0.87);
    transform: scale(0.87);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }
  45% {
    -webkit-transform: scale(1);
    transform: scale(1);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }
}
.ads {
  width: 100%;
}
header {
  margin: 0 auto;
  width: 320px;
  height: 100px;
  background-color: blue;
  color: white;
}

.slum-box-button {
  margin: 0 auto;
  text-align: center;
}

.btn {
  display: inline-block;
  font-weight: 400;
  color: #212529;
  text-align: center;
  vertical-align: middle;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  background-color: transparent;
  border: 1px solid transparent;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
}

button .close{
  color:white;
}

.pulsate-bck {
  -webkit-animation: pulsate-bck 0.5s ease-in-out infinite both;
  animation: pulsate-bck 0.5s ease-in-out infinite both;
}

.bigads{
  width:300px;
  height:250px;
  border:0;
}

.smallads{
  width:120px;
  height:240px;
  border:0;
}
/* ----------------------------------------------
 * Generated by Animista on 2019-8-26 11:32:25
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

@-webkit-keyframes pulsate-bck {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
  50% {
    -webkit-transform: scale(0.9);
    transform: scale(0.9);
  }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}
@keyframes pulsate-bck {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
  50% {
    -webkit-transform: scale(0.9);
    transform: scale(0.9);
  }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}
.slum-button {
  border: 1px solid #00cdcf;
  color: #b2b2b2;
  font-size: 1.5em;
  margin: 0 auto;
  padding: 25%;
  height: auto;
  width: auto;
  border-radius: 50%;
}
.buttonSocial{
  padding: 10px;
  background-color: white;
  font-size: 1em;
  border:1px solid black;
  color: black;
  border-radius: 0;
}
.buttonSocial-disabled{
  padding: 10px;
  background-color: white;
  font-size: 1em;
  border:1px solid #808080;
  color: #808080;
  border-radius: 0;
}
.buttonGoogle{

}
.buttonFacebook{

}
.slum-button:hover {
  border: 1px solid #523492;
  color: white;
  margin: 0 auto;
  background-color: #523492;
  padding: 25%;
  height: auto;
  width: auto;
  border-radius: 50%;
}

.my-facebook-button-class {
  font-size: 0.8em !important;
  font-family: "Aileron-Regular" !important;
  background-color: white !important;
  width: 100%;
  padding: 10px 10px 10px 0px !important;
}

.my-google-button-class {
  font-size: 0.8em !important;
  font-family: "Aileron-Regular" !important;
  background-color: white !important;
  width: 100%;
  padding: 0px !important;
}

.logout {
  padding-top: 3%;
  text-align: left;
  line-height: 0.3em;
  font-size: 1em;
  padding-left: 5%;
  padding-bottom: 3%;
}

.logout-button{
  border-radius: 0;
  padding: 15px;
  padding-left: 25px;
  padding-right: 25px;
  color: white;
  font-weight: bold;
  border:0;
  background-color: #e30613;
  margin-top: 2%;
}

.bottone-light{
  border-radius: 0;
  border:0;
  background-color: white;
  padding: 5px;
  padding-left: 20px;
  margin-left: 1%;
  padding-right: 20px;
}

.bottone-dark{
  border-radius: 0;
  border:0;
  background-color: #00e5ff;
  padding: 5px;
  padding-left: 20px;
  padding-right: 20px;
}

.bottone-buy {
  padding: 5px;

  width: 100%;

  background-color: #2fe93b;

  margin-top: 5%;
}

.bottoni {
  position: fixed;
  left: 0;
  width: 100%;
  color: white;
  text-align: center;
  height: 100%;
  border-top:1px solid rgb(178, 178, 178);
}

.bottone-buy-profile{
  padding: 15px;
  padding-left: 20px;
  padding-right: 20px;
  border-radius: 0px;
  background-color: #2fe93b;
  border:0px;
  margin-top: 2%;
}

.slum-button-active {
  background-color: lightseagreen;
  border: 0;
  color: white;
  margin: 0 auto;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  box-shadow: 0;
}

.noselect {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome and Opera */
}

.scale-in-center {
  -webkit-animation: scale-in-center 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94)
    0.2s both;
  animation: scale-in-center 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) 0.2s both;
}

/* ----------------------------------------------
 * Generated by Animista on 2019-8-27 16:43:7
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation scale-in-center
 * ----------------------------------------
 */
@-webkit-keyframes scale-in-center {
  0% {
    -webkit-transform: scale(0);
    transform: scale(0);
    opacity: 1;
  }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
    opacity: 1;
  }
}
@keyframes scale-in-center {
  0% {
    -webkit-transform: scale(0);
    transform: scale(0);
    opacity: 1;
  }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
    opacity: 1;
  }
}
.shake-horizontal {
	-webkit-animation: shake-horizontal 4s steps(23, end) 2s infinite both;
	        animation: shake-horizontal 4s steps(23, end) 2s infinite both;
}

.blink-1 {
	-webkit-animation: blink-1 4s 2s infinite both;
	        animation: blink-1 4s 2s infinite both;
}

.scale-out-center {
  -webkit-animation: scale-out-center 0.5s cubic-bezier(0.55, 0.085, 0.68, 0.53)
    both;
  animation: scale-out-center 0.5s cubic-bezier(0.55, 0.085, 0.68, 0.53) both;
  height: "0%";
  margin: "0 auto";
  margin-top: "0%";
}
/* ----------------------------------------------
 * Generated by Animista on 2019-9-5 11:29:10
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation blink-1
 * ----------------------------------------
 */
 @-webkit-keyframes blink-1 {
  0%,
  50%,
  100% {
    opacity: 1;
  }
  25%,
  75% {
    opacity: 0;
  }
}
@keyframes blink-1 {
  0%,
  50%,
  100% {
    opacity: 1;
  }
  25%,
  75% {
    opacity: 0;
  }
}

/* ----------------------------------------------
 * Generated by Animista on 2019-9-5 11:26:0
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation shake-horizontal
 * ----------------------------------------
 */
 @-webkit-keyframes shake-horizontal {
  0%,
  100% {
    -webkit-transform: translateX(0);
            transform: translateX(0);
  }
  10%,
  30%,
  50%,
  70% {
    -webkit-transform: translateX(-10px);
            transform: translateX(-10px);
  }
  20%,
  40%,
  60% {
    -webkit-transform: translateX(10px);
            transform: translateX(10px);
  }
  80% {
    -webkit-transform: translateX(8px);
            transform: translateX(8px);
  }
  90% {
    -webkit-transform: translateX(-8px);
            transform: translateX(-8px);
  }
}
@keyframes shake-horizontal {
  0%,
  100% {
    -webkit-transform: translateX(0);
            transform: translateX(0);
  }
  10%,
  30%,
  50%,
  70% {
    -webkit-transform: translateX(-10px);
            transform: translateX(-10px);
  }
  20%,
  40%,
  60% {
    -webkit-transform: translateX(10px);
            transform: translateX(10px);
  }
  80% {
    -webkit-transform: translateX(8px);
            transform: translateX(8px);
  }
  90% {
    -webkit-transform: translateX(-8px);
            transform: translateX(-8px);
  }
}

/* ----------------------------------------------
 * Generated by Animista on 2019-8-27 16:51:24
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation scale-out-center
 * ----------------------------------------
 */
@-webkit-keyframes scale-out-center {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1);
    opacity: 1;
  }
  100% {
    -webkit-transform: scale(0);
    transform: scale(0);
    opacity: 1;
  }
}
@keyframes scale-out-center {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1);
    opacity: 1;
  }
  100% {
    -webkit-transform: scale(0);
    transform: scale(0);
    opacity: 1;
  }
}
.scale-in-center-logo-image {
  -webkit-animation: scale-in-center 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94)
    0.1s both;
  animation: scale-in-center 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) 0.1s both;
}
/* ----------------------------------------------
 * Generated by Animista on 2019-8-27 17:16:25
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation scale-in-center
 * ----------------------------------------
 */
@-webkit-keyframes scale-in-center-logo-image {
  0% {
    -webkit-transform: scale(0);
    transform: scale(0);
    opacity: 1;
  }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
    opacity: 1;
  }
}
@keyframes scale-in-center-logo-image {
  0% {
    -webkit-transform: scale(0);
    transform: scale(0);
    opacity: 1;
  }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
    opacity: 1;
  }
}

.scale-in-center-logo-text {
  -webkit-animation: scale-in-center 0.2s cubic-bezier(0.25, 0.46, 0.45, 0.94)
    0.3s both;
  animation: scale-in-center 0.2s cubic-bezier(0.25, 0.46, 0.45, 0.94) 0.3s both;
}
/* ----------------------------------------------
 * Generated by Animista on 2019-8-27 17:16:25
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation scale-in-center
 * ----------------------------------------
 */
@-webkit-keyframes scale-in-center-logo-text {
  0% {
    -webkit-transform: scale(0);
    transform: scale(0);
    opacity: 1;
  }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
    opacity: 1;
  }
}
@keyframes scale-in-center-logo-text {
  0% {
    -webkit-transform: scale(0);
    transform: scale(0);
    opacity: 1;
  }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
    opacity: 1;
  }
}

.scale-out-center {
  -webkit-animation: scale-out-center 0.5s cubic-bezier(0.55, 0.085, 0.68, 0.53)
    both;
  animation: scale-out-center 0.5s cubic-bezier(0.55, 0.085, 0.68, 0.53) both;
}
/* ----------------------------------------------
 * Generated by Animista on 2019-8-28 11:49:51
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation scale-out-center
 * ----------------------------------------
 */
@-webkit-keyframes scale-out-center {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1);
    opacity: 1;
  }
  100% {
    -webkit-transform: scale(0);
    transform: scale(0);
    opacity: 1;
  }
}
@keyframes scale-out-center {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1);
    opacity: 1;
  }
  100% {
    -webkit-transform: scale(0);
    transform: scale(0);
    opacity: 1;
  }
}

@media (max-height: 520px) {
  .hide_keyboard {
    display: none;
  }
}

button:focus {
  outline: 0;
}
input:focus {
  outline: 0;
}

.logo {
  height: "50%";
  margin: "0 auto";
  margin-top: "10%";
}

.none {
  display: none;
  height: 0px;
}

.img-profilo {
  border-radius: 80%;
  margin: 0 auto;
  object-fit: cover;
  width:80px;
  height:80px;
  margin-top: 5%;
}
.p-switch {
  box-sizing: border-box;
  display: inline-block;
  font-size: 100%;
  height: 1.6em;
  position: relative; }
  .p-switch > span:first-child {
    margin-left: 6px; }
  .p-switch .p-switch-style {
    height: 1.6em;
    left: 0;
    background: #cecece;
    border-radius: .8em;
    display: inline-block;
    position: relative;
    top: 0;
    transition: all .3s ease-in-out;
    width: 3em;
    cursor: pointer;
    float: left; }
    .p-switch .p-switch-style:before {
      display: block;
      content: '';
      height: 1.4em;
      position: absolute;
      width: 1.4em;
      background-color: #fff;
      border-radius: 50%;
      left: .1em;
      top: .1em;
      transition: all .3s ease-in-out; }
  .p-switch > input[type="checkbox"] {
    display: none; }
    .p-switch > input[type="checkbox"]:checked + .p-switch-style {
      background-color: #5A6268; }
      .p-switch > input[type="checkbox"]:checked + .p-switch-style:before {
        left: 50%; }
    .p-switch > input[type="checkbox"]:checked[disabled] + .p-switch-style {
      opacity: 0.5; }
  .p-switch.p-switch-sm {
    font-size: 75%; }
  .p-switch.p-switch-lg {
    font-size: 125%; }
  .p-switch.p-switch-xl {
    font-size: 150%; }
  .p-switch > input[type="checkbox"][disabled] + .p-switch-style {
    cursor: not-allowed;
    opacity: 0.5; }
  .p-switch.switch-color-primary > input[type="checkbox"]:checked + .p-switch-style {
    background-color: #5A6268; }
  .p-switch.switch-color-secondary > input[type="checkbox"]:checked + .p-switch-style {
    background-color: #5A6268; }
  .p-switch.switch-color-success > input[type="checkbox"]:checked + .p-switch-style {
    background-color: #81c868; }
  .p-switch.switch-color-info > input[type="checkbox"]:checked + .p-switch-style {
    background-color: #53b0f8; }
  .p-switch.switch-color-danger > input[type="checkbox"]:checked + .p-switch-style {
    background-color: #f33e5b; }
  .p-switch.switch-color-warning > input[type="checkbox"]:checked + .p-switch-style {
    background-color: #ffb20e; }
  .p-switch.switch-color-light > input[type="checkbox"]:checked + .p-switch-style {
    background-color: #cecece; }
  .p-switch.switch-color-dark > input[type="checkbox"]:checked + .p-switch-style {
    background-color: #444; }

.p-switch-style {
  box-sizing: border-box; }
  .p-switch-style:before {
    box-sizing: border-box; }