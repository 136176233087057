@media only screen and (min-width: 550px) {
  .ananke-app {
    display: none;
  }
  .anake-lending{
    display:block;
  }
}

@media only screen and (max-width: 550px) {
  .anake-lending{
    display:none;
  }
}

.todo-button {
  border-width: 1px;
  border-color: #b5b5b5;
  width: 60%;
  height: 0;
  padding-bottom: 50%;
  border-radius: 50%;
  overflow: hidden;
  background: white;
  text-align: center;
  font-size: 1.4em;
}

.todo-button:hover {
  width: 60%;
  border-width: 0px;
  height: 0;
  padding-bottom: 50%;
  border-radius: 50%;
  overflow: hidden;
  color: white !important;
  background-color: #523492;
}

.todo-button-active {
  width: 60%;
  border-width: 0px;
  height: 0;
  font-size: 1.4em;
  padding-bottom: 50%;
  border-radius: 50%;
  overflow: hidden;
  color: white !important;
  background-color: #523492;
}

.todo-button-active:hover {
  width: 60%;
  border-width: 0px;
  height: 0;
  padding-bottom: 50%;
  border-radius: 50%;
  overflow: hidden;
  color: white !important;
  font-size: 1.4em;
  background-color: #523492;
}

.todo-button-text {
  vertical-align: middle;
  width: 100%;
  padding-top: 60%;
  color: #b5b5b5;
  font-weight: 300;
  font-family: "Aileron-Regular" !important;
}
.todo-button-text:hover {
    margin-left: -50%;
    float: center;
    width: 100%;
    padding-top: 60%;
    color: #b5b5b5;
    font-weight: 300;
    font-family: "Aileron-Regular" !important;
 }
  
.todo-button-text-active{
    margin-left: -50%;
    float: center;
    width: 100%;
    padding-top: 60%;
    font-family: "Aileron-Regular" !important;
    color:white;
    font-weight: 300;
}


.todo-button-text-active:hover{
    margin-left: -50%;
    float: center;
    width: 100%;
    padding-top: 60%;
    font-family: "Aileron-Regular" !important;
    color:white;
    font-weight: 300;
}